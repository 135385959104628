<div class="login-container">
  <div class="header-container">
    <span>
      <img src="./assets/images/Reve_logo.png" alt="Reve Logo">
    </span>
    <span class="header">
      Login
    </span>
  </div>
  <form [formGroup]="loginForm">
    <div>
      <mat-form-field appearance="outline">
        <mat-label>Business e-mail</mat-label>
        <input matInput placeholder="" formControlName="email">
        <mat-error *ngIf="loginForm.controls.email.invalid">Please Enter Valid e-mail ID</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput [type]="showPassword ? 'text' : 'password'" placeholder="" formControlName="password">
        <iconify-icon icon="iconamoon:eye-light" *ngIf="!showPassword" class="hide-icon"
          (click)="passwordVisibility()" matSuffix></iconify-icon>
        <iconify-icon icon="iconamoon:eye-off-light" *ngIf="showPassword" class="hide-icon"
          (click)="passwordVisibility()" matSuffix></iconify-icon>
        <mat-error *ngIf="loginForm.controls.password.invalid">Minimum 8 character Password. It should contains numbers,
          letters, and special characters</mat-error>
      </mat-form-field>
    </div>
    <div class="forgot-password">
      <span (click)="forgotPassword()">Forgot Password?</span>
    </div>
    <div class="button-container">
      <button mat-button color="primary" (click)="login()" class="submit-button" [disabled]="loginForm.invalid">
        <i class="fa fa-spinner fa-spin" *ngIf="loginSpinner"></i>Login</button>
    </div>
  </form>
</div>