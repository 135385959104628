<div class="main-container">
  <div class="right-container">
    <div>
      <div class="image-container">
        <img src="./assets/images/Group.png" alt="Website Image">
      </div>
      <div class="text-container">
        <p class="introText">Secure and efficient access to your cloud services</p>
        <p class="subText">Your Cloud, Your Way - Login and Get Started</p>
      </div>
    </div>
  </div>
  <div class="form-container">
    <app-login *ngIf="isLogin && !isResetPassword" (outfilter)="showResetPassword($event)"></app-login>
    <app-reset-password *ngIf="isResetPassword && !isLogin" (emitter)="showLogin($event)"></app-reset-password>
  </div>
</div>