export const colorObj = {​​​
    401: "error",
    403: "error",
    408: "warning",
    404: "warning",
    400: "warning",
    409: "warning",
    412: "warning",
    422: "warning",
    500: "error"
  }