import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'admin-dashboard',
    loadChildren: () => import('./admin-management/admin-management-routes').then(m => m.AdminRoutes)
  },
  {
    path: 'internal-dashboard',
    loadChildren: () => import('./super-admin-management/super-admin-management-route').then(m => m.SuperAdminRoutes)
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }