import { HttpClient, HttpContext, HttpContextToken } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
export const BYPASS_LOG = new HttpContextToken(() => false);
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public resourceUrl = `${environment.API_BASE_URL}`;

  constructor(private http: HttpClient) { }

  getMasterData(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/reve/master-data`, { context: new HttpContext().set(BYPASS_LOG, true) });
  }

  login(obj: any): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/reve/auth/login/email`, obj, { context: new HttpContext().set(BYPASS_LOG, true) });
  }

  getClientInfo(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/reve/client/info`);
  }

}