import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  isOTP: boolean = false;
  isSetPassword: boolean = false;
  isSuccess: boolean = false;
  @Output() emitter: EventEmitter<any> = new EventEmitter<any>;

  constructor() { }

  ngOnInit(): void {
  }

  getOtp() {
    this.isOTP = true;
    this.isSetPassword = false;
    this.isSuccess = false;
  }

  verifyOtp() {
    this.isSetPassword = true;
    this.isOTP = false;
    this.isSuccess = false;
  }

  resetPassword() {
    this.isSuccess = true;
    this.isSetPassword = false;
    this.isOTP = false;
  }

  redirectToLogin() {
    this.emitter.emit({ 'content': 'login' })
  }

}
