import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { BYPASS_LOG } from './service/auth.service';

@Injectable()
export class ConfigInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const userInfo = JSON.parse(window.localStorage.getItem('userInfo') || '{}');
    if (request.context.get(BYPASS_LOG) === true) {
      return next.handle(request);
    } else {
      return next.handle(request.clone({
        setHeaders: {
          'x-access-token': userInfo?.access_token,
          'id-token': userInfo?.id_token,
        }
      }));
    }
  }

}