import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  isLogin: boolean = true;
  isResetPassword:boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.isLogin = true;
  }

  showResetPassword(event:any){
    this.isLogin = false;
    this.isResetPassword = true;
  }

  showLogin(event:any){
    this.isLogin = true;
    this.isResetPassword = false;
  }

}
