import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private snackBar: MatSnackBar,
    private router: Router
  ) { }

  showNotification(
    message: string,
    verticalPosition: 'top' | 'bottom' = 'top',
    panelClass: 'success' | 'error' | 'warning' = 'success',
    status: number) {
    if (status == 401) {
      window.localStorage.clear();
      this.router.navigate(['auth/login']);
      window.location.reload();
    }
    return this.snackBar.open((!!message ? message : 'API failed, Please try again after sometime!!'), '', { verticalPosition, duration: 4000, panelClass: [panelClass] });
  }

}
