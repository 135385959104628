import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';
import { NotificationService } from 'src/app/service/notification.service';
import { colorObj } from 'src/app/shared/color-object';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  showPassword: boolean = false;
  emailPattern = environment.EMAIL_PATTERN;
  passwordPattern = environment.PASSWORD_PATTERN;
  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
    password: new FormControl('', [Validators.required, Validators.pattern(this.passwordPattern)])
  });
  @Output() outfilter: EventEmitter<any> = new EventEmitter<any>;
  loginSpinner: boolean = false;
  subscription: Subscription;

  constructor(
    private authService: AuthService,
    private notify: NotificationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getMasterData();
  }

  getMasterData() {
    this.authService.getMasterData().subscribe({
      next: (data) => {
        for (let md_key in data.object[0]) {
          data.object[0][md_key].shift();
          window.localStorage.setItem(md_key, JSON.stringify(data.object[0][md_key]));
        }
      },
      error: (e) => {
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  forgotPassword() {
    this.outfilter.emit({ 'content': 'forgotPassword' });
  }

  login() {
    this.loginSpinner = true;
    let obj = {
      "USERNAME": this.loginForm.value.email,
      "PASSWORD": this.loginForm.value.password
    }
    this.subscription = this.authService.login(obj).subscribe({
      next: (data) => {
        window.localStorage.setItem(
          'userInfo',
          JSON.stringify({
            userId: data.object.USER_ID,
            C_ID: data.object.C_ID,
            firstName: data.object.FIRST_NAME,
            lastName: data.object.LAST_NAME,
            emailId: data.object.EMAIL_ID,
            mobile: data.object.MOBILE,
            emailVerified: data.object.EMAIL_VERIFIED,
            mobileVerified: data.object.MOBILE_VERIFIED,
            roleId: data.object.ROLE_ID,
            serviceId: data.object.SERVICE_ID,
            access_token: data.object.TOKEN_DATA.ACCESS_TOKEN,
            id_token: data.object.TOKEN_DATA.ID_TOKEN,
            refresh_token: data.object.TOKEN_DATA.REFRESH_TOKEN
          })
        )
        window.localStorage.setItem('id_token', data.object.TOKEN_DATA.ID_TOKEN);
        window.localStorage.setItem('access_token', data.object.TOKEN_DATA.ACCESS_TOKEN);
        this.authService.getClientInfo().subscribe({
          next: (clientData) => {
            window.localStorage.setItem('org_name', clientData.object.ORG_NAME);
            window.localStorage.setItem('org_logo', clientData.object.ATTACHMENT_SOURCE);
            this.loginSpinner = false;
            if (data.object.ROLE_ID == 1) {
              this.router.navigateByUrl('/internal-dashboard/client-management');
            }
            else if (data.object.ROLE_ID == 2) {
              this.router.navigateByUrl('/internal-dashboard/user-management');
            }
            else if (data.object.ROLE_ID == 3 || data.object.ROLE_ID == 4) {
              this.router.navigateByUrl('/admin-dashboard/service-dashboard');
            }
          },
          error: (e) => {
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      },
      error: (e) => {
        this.loginSpinner = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
        this.loginForm.reset();
      }
    })
  }

  passwordVisibility() {
    this.showPassword = !this.showPassword;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}