<div class="reset-container">
    <div *ngIf="!isOTP && !isSetPassword && !isSuccess" class="verify-email-container">
        <div class="header">
            Reset Password
        </div>
        <div>
            <mat-form-field appearance="outline">
                <mat-label>Enter Registered Email</mat-label>
                <input matInput type="text" placeholder="" />
            </mat-form-field>
            <div class="button-container">
                <button mat-button color="primary" class="submit-button" (click)="getOtp()">Get OTP</button>
            </div>
        </div>
    </div>
    <div *ngIf="isOTP && !isSetPassword && !isSuccess" class="otp-main-container">
        <div class="header-container">
            <span class="header">
                Verify EmailID
            </span>
            <span class="subheader">
                Please enter the verification code sent to your e-mail address.
            </span>
        </div>
        <div class="otp-container">
            <input type="number" class="otp-box" />
            <input type="number" class="otp-box" />
            <input type="number" class="otp-box" />
            <input type="number" class="otp-box" />
            <input type="number" class="otp-box" />
            <input type="number" class="otp-box" />
        </div>
        <div class="button-container">
            <button mat-button color="primary" class="submit-button" (click)="verifyOtp()">Verify</button>
        </div>
    </div>
    <div *ngIf="!isOTP && !isSuccess && isSetPassword" class="password-container">
        <div class="header-container">
            <span class="header">
                Reset Password
            </span>
            <span class="subheader">
                Create New Password
            </span>
        </div>
        <div>
            <mat-form-field appearance="outline">
                <mat-label>Enter New Password</mat-label>
                <input matInput placeholder="">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Confirm New Password</mat-label>
                <input matInput placeholder="">
            </mat-form-field>
            <div class="button-container">
                <button mat-button color="primary" class="submit-button" (click)="resetPassword()">Reset
                    Password</button>
            </div>
        </div>
    </div>
    <div *ngIf="!isOTP && isSuccess && !isSetPassword" class="success-container">
        <div class="success-box">
            <img src="../../../assets/images/success.png" class="success-img" />
            <span class="success-message">
                Your password has been successfully changed
            </span>
        </div>
        <div class="button-container">
            <button mat-button color="primary" class="submit-button" (click)="redirectToLogin()">Go to Login</button>
        </div>
    </div>
</div>